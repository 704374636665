/* Open Sans 300 Light */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../public/fonts/OpenSans-Light.ttf') format('truetype');
}

/* Open Sans 400 Regular */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/OpenSans-Regular.ttf') format('truetype');
}

/* Open Sans 600 Semi-Bold */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

/* Open Sans 700 Bold */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/OpenSans-Bold.ttf') format('truetype');
}

/* Open Sans 800 Extra-Bold */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('../public/fonts/OpenSans-ExtraBold.ttf') format('truetype');
}


/* Open Sans 300 Light */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../public/fonts/OpenSans_Condensed-Light.ttf') format('truetype');
}

/* Open Sans Condensed 400 Regular */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/OpenSans_Condensed-Regular.ttf') format('truetype');
}

/* Open Sans Condensed 600 Semi-Bold */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/OpenSans_Condensed-SemiBold.ttf') format('truetype');
}

/* Open Sans Condensed 700 Bold */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 700;
    src: url('../public/fonts/OpenSans_Condensed-Bold.ttf') format('truetype');
}

/* Open Sans Condensed 800 Extra-Bold */
@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: 800;
    src: url('../public/fonts/OpenSans_Condensed-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: bold;
    src: url('../public/fonts/OpenSans_Condensed-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans Condensed';
    font-style: normal;
    font-weight: bolder;
    src: url('../public/fonts/OpenSans_Condensed-ExtraBold.ttf') format('truetype');
}