/* ChatStyles.css */

/* Container for the entire chat app */
.chat-container {
  width: 100%;
  height: 90vh;
  margin: 0;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

/* Chat header */
.chat-header {
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  background-color: #3490dc;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
}

/* Container for chat messages */
.chat-messages {
  max-height: 80%;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #888;
  border-radius: 8px;
  padding: 10px;
  background-color: white;
}

/* Individual chat message */
.message {
  display: flex;
}

/* Participant's chat message */
.left {
  justify-content: flex-start;
}

/* User's chat message */
.right {
  justify-content: flex-end;
}

/* Participant tag */
.participant-tag,
.user-tag,
.status-tag {
    font-weight: "700";
    font-size: 12px;
    margin-right: 5px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color:  #84909D;
    color: white;
    font-weight: "600";
    display: flex;
    justify-content: center;
    align-items: center;
}

.participant-tag.user {
    background-color: #3490DC;
}

.user-tag {
  color: #888;
}

.status-tag {
  font-style: italic;
}

.status-tag.loading {
  color: green;
}

.status-tag.error {
  color: red;
}

/* Chat bubble */
.message-bubble {
  padding: 10px;
  border-radius: 10px;
  position: relative;
}

.loading {
  width: 25px;
  height: 25px;
  border: 2px solid #ccc;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 20%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* User input area */
.user-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

/* Input field */
.user-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Send button */
.send-button {
  background-color: #3490dc;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Random options */
.random-options {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 10px;
  overflow: auto;
}

/* Blue button */
.blue-button {
  background-color: #ffffff;
  color: #3490dc;
  padding: 10px 20px;
  border: 1px solid #3490dc;
  border-radius: 5px;
  cursor: pointer;
}

/* ChatStyles.css */

/* ... (previous styles) ... */

/* Individual chat message container */
.message {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}

/* Participant's chat message */
.left .message-bubble {
    background-color: #84909D;
    color: white;
    border-top-left-radius: 0;
}

/* User's chat message */
.right .message-bubble {
    background-color: #3490DC;
    color: white;
    border-top-right-radius: 0;
}

/* Chat bubble */
.message-bubble {
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  /* Limit bubble width */
}

/* ... (remaining styles) ... */

/* ChatLoading.css */

.chat-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white background */
  z-index: 2;
  /* Place it above chat bubbles */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3490dc;
  /* Spinner color */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media screen and (width < 450px) {
  .message-bubble {
    max-width: 90%;
  }

  .chat-container {
    height: 90vh;
    padding: 10px;
  }
}
