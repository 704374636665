.multi-select-box {
  background: #ffffff;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  padding: 17px 0;
}

.multi-select-box:hover {
  cursor: pointer;
}

.active {
  color: white;
  background-color: #0876dc;
}

#consultation-form .form-label {
  margin-top: 20px;
  color: #0876dc;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
}

#consultation-form input {
  text-align: center;
}

#consultation-form .form-input {
  min-height: 50px;
  box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  font-size: 20px;
  font-weight: 700;
}
