html {
	scroll-behavior: smooth;
	animation: ease-in-out 500ms both;
}

html,
body {
	/* min-height: "100%"; */
	width: "100%";
	font-family: "Open Sans";
	margin: 0;
	color: "#2E3A40"
}